/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a",
    hr: "hr",
    p: "p",
    img: "img",
    h3: "h3",
    strong: "strong",
    pre: "pre",
    code: "code",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Resources"), "\n", React.createElement(_components.h2, null, "Supper Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://omatsuri.app/"
  }, "Omatsuri")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Philosophy"), "\n", React.createElement(_components.h2, null, "Atomic Design"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://atomicdesign.bradfrost.com/"
  }, "Atomic Design Manifesto")), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/design-assets/atomic-design-four.png",
    alt: ""
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/design-assets/atomic-design-two.jpeg",
    alt: ""
  })), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/images/design-assets/atomic-design-three.png",
    alt: ""
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "UX"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lawsofux.com"
  }, "Laws of UX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.uisources.com/interactions"
  }, "Real World Design Patterns")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://coglode.com"
  }, "Coglode - UX behavioral insight")), "\n"), "\n", React.createElement(_components.h3, null, "Design Inspiration"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.designspiration.com/"
  }, "Designspiration")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://mobbin.com/browse/ios/apps"
  }, "Mobbin"), "\n", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "https://owwly.com/products/newest"
  }, "Owwly")), "\nA curated list of upcoming and stand-out projects. A good place to see emerging bleeding-edge design trends."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.siteinspire.com/"
  }, "SiteInspire")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://designspiration.net"
  }, "DesignInpiration")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.awwwards.com"
  }, "Awwwards")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://wow-web.co.uk/"
  }, "wow-web")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://godly.website/"
  }, "godly")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.typewolf.com/"
  }, "typewolf")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://land-book.com/"
  }, "land book")), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "User Research and Feedback"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.emojics.com/"
  }, "Emojics")), "\n"), "\n", React.createElement(_components.h2, null, "Accessibility"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/notes/accessibility-notes"
  }, "Accessibility")), "\n", React.createElement(_components.h3, null, "Accessibility Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coolors.co/contrast-checker/dcdcdc-545f50"
  }, "Color Contract checker ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://accessiblepalette.com/"
  }, "Accessible Palette")), "\n"), "\n", React.createElement(_components.h3, null, "Accessibility References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://wildbit.com/blog/accessible-palette-stop-using-hsl-for-color-systems"
  }, "Accessible palette - Wildbit")), "\n"), "\n", React.createElement(_components.h2, null, "Color"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coolors.co/"
  }, "Coolors")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colors.dopely.top/"
  }, "Color Dopely")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colorchef.vercel.app/"
  }, "colorchef")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://huemint.com/brand-intersection/"
  }, "huemint")), "\n"), "\n", React.createElement(_components.h3, null, "Color  Inspiration"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nipponcolors.com/"
  }, "nippon colors")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://colormind.io"
  }, "AI pallets Maker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.colorsandfonts.com"
  }, "Color and Fonts")), "\n"), "\n", React.createElement(_components.h3, null, "Color Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://culrs.com/#/"
  }, "Curls"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "tones, shades, tints"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://htmlcolorcodes.com/color-picker"
  }, "htmlcoloCodes - dev-friendly color")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colorbox.io/"
  }, "Color Box"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "tone, tint, shade generator"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://huemint.com/brand-intersection/"
  }, "Huemint"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "robust tool for palette discovery, for example in web, gradients, illustration"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://colorsupplyyy.com/app"
  }, "ColorSupply - pallete maker "), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "picker with references and examples"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colorswall.com/"
  }, "ColorWall"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "store palette"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mycolor.space/"
  }, "MyColor.space"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "palette generator"), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://color.adobe.com/create"
  }, "Adobe Pallet Maker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Colors/Color_picker_tool"
  }, "Color picker - MDN")), "\n"), "\n", React.createElement(_components.h3, null, "Color  Palettes"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colorsui.com/"
  }, "Color UI")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.happyhues.co"
  }, "Happy Hues - pallets and example ui")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colorwise.io/"
  }, "ColorWise - color pallets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.colorhunt.co"
  }, "ColorHunt - color pallets")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.palettelist.com/"
  }, "Palette list")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.schemecolor.com/"
  }, "Scheme Color")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colordrop.io/"
  }, "ColorDrop")), "\n"), "\n", React.createElement(_components.h3, null, "Tones, Tints, Shades"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://noeldelgado.github.io/shadowlord/#ffd800"
  }, "shadowlord")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.shadegenerator.com/"
  }, "Shade Generator ******")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://colors.dopely.top/color-toner/da4d40-shades-24"
  }, "Color Toner - dopely.top ")), "\n"), "\n", React.createElement(_components.h3, null, "Gradients, Aurora and Glass"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://webgradients.com/"
  }, "Web Gradients")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.grabient.com/"
  }, "Grabient")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gradients.cssgears.com"
  }, "Gradients - with css")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css.glass/"
  }, "css.glass")), "\n"), "\n", React.createElement(_components.h3, null, "Color Tools and Other"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/converting-color-spaces-in-javascript/"
  }, "formulas to convert color hex - rgb")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://sipapp.io/#!"
  }, "Sipp - pallete manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://app.haikei.app/"
  }, "haikei")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Typography"), "\n", React.createElement(_components.h3, null, "Technical Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/google/woff2"
  }, "Utilities to create and convert Web Open Font File (WOFF) files")), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "brew install woff2\n")), "\n", React.createElement(_components.h3, null, "Typography Resources"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.richardbaird.co.uk/2011/09/22/a-guide-to-typography"
  }, "A Guide to Typography - richardbaird.co"), "\n", React.createElement(_components.a, {
    href: "https://practicaltypography.com/index.html#toc"
  }, "practical typography")), "\n", React.createElement(_components.h4, null, "Type Foundries"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.fontshare.com/"
  }, "Font Share")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://open-foundry.com/fonts"
  }, "Open Foundry")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://uncut.wtf/"
  }, "Uncut")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://velvetyne.fr/"
  }, "Velvetyne")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.tunera.xyz/"
  }, "Tunera")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://vj-type.com/"
  }, "VJ type")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://sharptype.co/"
  }, "SharpType")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.atipofoundry.com/fonts/wotfard"
  }, "Atipo Foundry")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.indiantypefoundry.com/fonts/begum-sans"
  }, "Indian Type Foundry")), "\n"), "\n"), "\n", React.createElement(_components.h4, null, "Type Aggregator"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.fontspring.com/"
  }, "Fontspring")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gitlab.com/raphaelbastide/libre-foundries"
  }, "Raphael Bastinde Font Foundry List")), "\n"), "\n", React.createElement(_components.h3, null, "Typography Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://type-scale.com/"
  }, "Type scale mocker - Type Scale")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fontjoy.com/"
  }, "Pairing tool - fontjoy")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, null, "Video"), "\n", React.createElement(_components.h4, null, "Resource"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mixkit.co/"
  }, "mixkit - free assets")), "\n"), "\n", React.createElement(_components.h2, null, "Logo/Mark"), "\n", React.createElement(_components.h4, null, "Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hundredrabbits.itch.io/dotgrid"
  }, "Dotgrid - logo maker tool")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.thelogofactory.com"
  }, "Curated Logo List")), "\n"), "\n", React.createElement(_components.h3, null, "Image"), "\n", React.createElement(_components.h3, null, "Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://icons8.com/upscaler"
  }, "AI image enhancement")), "\n"), "\n", React.createElement(_components.h3, null, "Design Systems / Webkits"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.designsystemchecklist.com"
  }, "Design System checklist")), "\n"), "\n", React.createElement(_components.h2, null, "Iconography"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://thenounproject.com"
  }, "The Noun project ")), "\n"), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gitlab.com/raphaelbastide/libre-foundries"
  }, "Raphael Bastinde Font Foundry List")), "\n"), "\n", React.createElement(_components.h2, null, "Other"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://metatags.io/"
  }, "Meta tag previewer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.canva.com/learn/graphic-design-terms"
  }, "Graphic design terms")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.blobmaker.app"
  }, "blobmaker")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
